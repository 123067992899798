import { createSlice } from "@reduxjs/toolkit";
import APIstatus from "redux/constants/common";
import { getAllLanguages } from "./languageThunk";

const initialState = {
  languageRes: { status: 0, data: [] },
};

export const languageSlice = createSlice({
  name: "language",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAllLanguages.pending.toString(), (state) => {
      return {
        ...state,
        languageRes: { status: APIstatus.IN_PROGRESS, data: [] },
      };
    });
    builder.addCase(getAllLanguages.fulfilled.toString(), (state, action) => {
      return {
        ...state,
        languageRes: {
          status: APIstatus.SUCCESS,
          data: action.payload,
        },
      };
    });
    builder.addCase(getAllLanguages.rejected.toString(), (state) => {
      return {
        ...state,
        languageRes: { status: APIstatus.FAILURE, data: [] },
      };
    });
  },
});
