import { createSlice } from "@reduxjs/toolkit";
import APIstatus from "redux/constants/common";
import {
  login,
  signUp,
  updateUser,
  getCurrentUserData,
  updatePassword,
} from "./authThunk";

const initialState = {
  loginRes: { status: "", data: undefined },
  signUpRes: { status: "", data: undefined },
  updateUserRes: { status: "", data: undefined },
  getCurrentUserDataRes: { status: "", data: undefined },
  updatePasswordRes: { status: "", data: undefined },
  otherLoader: false,
  token: "",
  authMessage: "",
};

export const authSlice = createSlice({
  name: "Auth",
  initialState,
  reducers: {
    setOtherLoader: (state, action) => {
      state.otherLoader = action.payload;
    },
    authenticatedToken: (state, action) => {
      state.token = action.payload;
    },
    setShowAuthMessage: (state, action) => {
      state.authMessage = action.payload;
    },
    setHideAuthMessage: (state, action) => {
      state.authMessage = action.payload;
    },
    setSignOutSuccess: (state, action) => {
      state.token = action.payload;
    },
    setSignUpSuccess: (state, action) => {
      state.token = action.payload.token;
    },
    setSignInWithGoogleAuthenticated: (state, action) => {
      state.token = action.payload.token;
    },
    setSignInWithFacebookAuthenticated: (state, action) => {
      state.token = action.payload.token;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(login.pending.toString(), (state) => {
      return {
        ...state,
        loginRes: { status: APIstatus.IN_PROGRESS, data: [] },
      };
    });
    builder.addCase(login.fulfilled.toString(), (state, action) => {
      return {
        ...state,
        loginRes: { status: APIstatus.SUCCESS, data: action.payload },
      };
    });
    builder.addCase(login.rejected.toString(), (state) => {
      return {
        ...state,
        loginRes: { status: APIstatus.FAILURE, data: [] },
      };
    });

    builder.addCase(signUp.pending.toString(), (state) => {
      return {
        ...state,
        signUpRes: { status: APIstatus.IN_PROGRESS, data: [] },
      };
    });
    builder.addCase(signUp.fulfilled.toString(), (state, action) => {
      return {
        ...state,
        signUpRes: { status: APIstatus.SUCCESS, data: action.payload },
      };
    });
    builder.addCase(signUp.rejected.toString(), (state) => {
      return {
        ...state,
        signUpRes: { status: APIstatus.FAILURE, data: [] },
      };
    });

    builder.addCase(updateUser.pending.toString(), (state) => {
      return {
        ...state,
        updateUserRes: { status: APIstatus.IN_PROGRESS, data: [] },
      };
    });
    builder.addCase(updateUser.fulfilled.toString(), (state, action) => {
      return {
        ...state,
        updateUserRes: { status: APIstatus.SUCCESS, data: action.payload },
      };
    });
    builder.addCase(updateUser.rejected.toString(), (state) => {
      return {
        ...state,
        updateUserRes: { status: APIstatus.FAILURE, data: [] },
      };
    });

    builder.addCase(getCurrentUserData.pending.toString(), (state) => {
      return {
        ...state,
        getCurrentUserDataRes: { status: APIstatus.IN_PROGRESS, data: [] },
      };
    });
    builder.addCase(
      getCurrentUserData.fulfilled.toString(),
      (state, action) => {
        return {
          ...state,
          getCurrentUserDataRes: {
            status: APIstatus.SUCCESS,
            data: action.payload,
          },
        };
      }
    );
    builder.addCase(getCurrentUserData.rejected.toString(), (state) => {
      return {
        ...state,
        getCurrentUserDataRes: { status: APIstatus.FAILURE, data: [] },
      };
    });

    builder.addCase(updatePassword.pending.toString(), (state) => {
      return {
        ...state,
        updatePasswordRes: { status: APIstatus.IN_PROGRESS, data: [] },
      };
    });
    builder.addCase(updatePassword.fulfilled.toString(), (state, action) => {
      return {
        ...state,
        updatePasswordRes: {
          status: APIstatus.SUCCESS,
          data: action.payload,
        },
      };
    });
    builder.addCase(updatePassword.rejected.toString(), (state) => {
      return {
        ...state,
        updatePasswordRes: { status: APIstatus.FAILURE, data: [] },
      };
    });
  },
});

export const {
  setOtherLoader,
  authenticatedToken,
  setShowAuthMessage,
  setHideAuthMessage,
  setSignOutSuccess,
  setSignUpSuccess,
  setSignInWithGoogleAuthenticated,
  setSignInWithFacebookAuthenticated,
} = authSlice.actions;
