import React, { Component } from "react";
import { UserOutlined } from "@ant-design/icons";
import { Drawer, Menu, Button, Modal, Rate, Input, Form } from "antd";
import ThemeConfigurator from "./ThemeConfigurator";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import { DIR_RTL } from "constants/ThemeConstant";
import { APP_PREFIX_PATH } from "configs/AppConfig";
import { submitFeedback } from "redux/toolkit/feedback/feedbackThunk";
import tokenService from "auth/tokenService";
import { getCurrentUserData } from "redux/toolkit/auth/authThunk";
import APIstatus from "redux/constants/common";
import posthog from "posthog-js";
import { getAllLanguages } from "redux/toolkit/languages/languageThunk";
import {
  saveAllLangToLocalStorage,
  setGlobalLanguageId,
} from "utils/commonFunctions";

export class NavPanel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      feedbackVisible: false,
      feedback: {
        user_id: tokenService.getUserId(),
        stars: 0,
        message: "",
      },
      feedbackErrors: {
        stars: null,
        message: null,
      },
      userName: "",
    };
  }

  componentDidMount = () => {
    this.props.getCurrentUserData(tokenService.getUserId());
    this.props.getAllLanguages();
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (
      prevProps.getCurrentUserDataRes.status !==
        this.props.getCurrentUserDataRes.status &&
      this.props.getCurrentUserDataRes.status === APIstatus.SUCCESS
    ) {
      this.setState({
        userName: this.props.getCurrentUserDataRes.data.first_name,
      });
    }

    if (
      prevProps.languageRes.status !== this.props.languageRes.status &&
      this.props.languageRes.status === APIstatus.SUCCESS
    ) {
      if (this.props.languageRes.data.length) {
        let lang = this.props.languageRes.data.filter((lang) => {
          if (lang.code === "en") {
            return lang.id;
          }
        });
        setGlobalLanguageId(lang[0].id);
      }
      saveAllLangToLocalStorage(this.props.languageRes.data);
    }
  };

  showDrawer = () => {
    this.setState({
      visible: true,
    });
  };

  onClose = () => {
    this.setState({
      visible: false,
    });
  };

  logOut = () => {
    tokenService.deleteToken();
    tokenService.deleteUserId();
    tokenService.deleteUserName();
    posthog.reset();
    this.props.history.push("/auth");
  };

  showFeedbackModal = () => {
    this.setState({
      feedbackVisible: true,
    });
  };

  handleFeedbackCancel = () => {
    this.setState({
      feedbackVisible: false,
      feedbackErrors: {
        stars: null,
        message: null,
      },
    });
  };

  handleFeedbackChange = (key, value) => {
    this.setState({
      feedback: {
        ...this.state.feedback,
        [key]: value,
      },
      feedbackErrors: {
        ...this.state.feedbackErrors,
        [key]: null,
      },
    });
  };

  validateFeedback = () => {
    const { stars, message } = this.state.feedback;
    const errors = {
      stars: stars === 0 ? "Please provide a stars." : null,
      message:
        message.trim() === "" ? "Please provide feedback message." : null,
    };
    this.setState({ feedbackErrors: errors });
    return !errors.stars && !errors.message;
  };

  handleFeedbackSubmit = () => {
    if (this.validateFeedback()) {
      this.props.submitFeedback(this.state.feedback);
      this.setState({
        feedbackVisible: false,
        feedback: {
          user_id: tokenService.getUserId(),
          stars: 0,
          message: "",
        },
        feedbackErrors: {
          stars: null,
          message: null,
        },
      });
    }
  };

  render() {
    const { feedback, feedbackErrors } = this.state;
    return (
      <div className="nav-content d-flex align-items-center justify-content-between flex-wrap">
        <Button className="btn-outline-dark" onClick={this.showFeedbackModal}>
          Submit Feedback
        </Button>
        <Menu mode="horizontal">
          {/* <Menu.Item key="feedback"></Menu.Item> */}
          {/* <Menu.Item key="settings" onClick={this.showDrawer}>
            <span>
              <SettingOutlined className="nav-icon mr-0" />
            </span>
          </Menu.Item> */}
          <Menu.SubMenu
            key="user"
            className="user-menu dark"
            icon={<UserOutlined />}
            title={this.state.userName || "User"}
          >
            <Link to={`${APP_PREFIX_PATH}/edit-user`}>
              <Menu.Item key="editUser">Edit User</Menu.Item>
            </Link>
            <Link to={`${APP_PREFIX_PATH}/change-password`}>
              <Menu.Item key="changePassword">Change Password</Menu.Item>
            </Link>
            <Menu.Item key="logout" onClick={this.logOut}>
              Logout
            </Menu.Item>
          </Menu.SubMenu>
        </Menu>
        <Drawer
          title="Settings"
          placement={this.props.direction === DIR_RTL ? "left" : "right"}
          width={350}
          onClose={this.onClose}
          visible={this.state.visible}
        >
          <ThemeConfigurator />
        </Drawer>
        <Modal
          className="feedback-modal"
          title="Submit Feedback"
          visible={this.state.feedbackVisible}
          onCancel={this.handleFeedbackCancel}
          onOk={this.handleFeedbackSubmit}
          footer={[
            <Button key="back" onClick={this.handleFeedbackCancel}>
              Cancel
            </Button>,
            <Button
              key="submit"
              type="primary"
              className="btn-dark"
              onClick={this.handleFeedbackSubmit}
            >
              OK
            </Button>,
          ]}
        >
          <Form layout="vertical">
            <Form.Item
              label="Rating"
              validateStatus={feedbackErrors.stars ? "error" : ""}
              help={feedbackErrors.stars}
            >
              <Rate
                value={feedback.stars}
                onChange={(value) => this.handleFeedbackChange("stars", value)}
              />
            </Form.Item>
            <Form.Item
              label="Feedback"
              validateStatus={feedbackErrors.message ? "error" : ""}
              help={feedbackErrors.message}
            >
              <Input.TextArea
                rows={4}
                placeholder="Your feedback"
                value={feedback.message}
                onChange={(e) =>
                  this.handleFeedbackChange("message", e.target.value)
                }
              />
            </Form.Item>
          </Form>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { locale } = state.theme;
  const { languageRes } = state.languages;
  const { feedbackRes } = state.feedback;
  const { getCurrentUserDataRes } = state.auth;
  return { locale, feedbackRes, getCurrentUserDataRes, languageRes };
};

const mapDispatchToProps = {
  submitFeedback,
  getCurrentUserData,
  getAllLanguages,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(NavPanel));
