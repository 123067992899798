import React from "react";
import "./payment.css";
import { Button, message } from "antd";
import { makePayment, verifyPayment } from "redux/toolkit/payment/paymentThunk";
import { connect } from "react-redux";
import { useEffect } from "react";
import usePageTitle from "utils/usePageTitle";

const Payment = (props) => {
  usePageTitle("Payment");
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://checkout.razorpay.com/v1/checkout.js";
    script.async = true;
    document.body.appendChild(script);
  }, []);

  const makePayment = () => {
    props.makePayment({ amount: 100 }).then((response) => {
      if (response.payload.status === "created") {
        const options = {
          key: "rzp_test_Pm4tM9gVn6Hw5b", // Enter the Key ID generated from the Dashboard
          amount: response.payload.amount * 100, // Razorpay accepts the amount in paisa (currency subunits)
          currency: response.payload.currency,
          name: "Infonium Technologies",
          description: "Test Transaction",
          image:
            "https://www.infoniumtech.com/wp-content/uploads/2023/11/logo.png", // Replace with your company logo URL
          order_id: response.payload.id, // This is a mandatory parameter

          handler: async (response) => {
            const verifyResponse = await props.verifyPayment(response);
            if (verifyResponse.payload.success) {
              message.success("Payment Successful");
            } else {
              message.error("Payment Verification Failed");
            }
          },
          prefill: {
            name: "Sagar Keshvala",
            email: "sagar@sagar.com",
            contact: "9999999999",
          },
          notes: {
            address: "Corporate Office",
          },
          theme: {
            color: "#3399cc",
          },
        };

        const rzp1 = new window.Razorpay(options);
        rzp1.open();
        rzp1.on("payment.failed", (response) => {
          console.log(response);
        });
        rzp1.on("payment.success", (response) => {
          console.log(response);
        });
      } else {
        message.error("Payment initiation failed");
      }
    });
  };

  return (
    <>
      <div style={{ marginTop: "100px" }}>
        <Button onClick={() => makePayment()}>Pay 100₹</Button>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  const { paymentRes } = state.payment;
  return { paymentRes };
};

const mapDispatchToProps = {
  makePayment,
  verifyPayment,
};

export default connect(mapStateToProps, mapDispatchToProps)(Payment);
