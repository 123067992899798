import { createSlice } from "@reduxjs/toolkit";
import APIstatus from "redux/constants/common";
import { makePayment, verifyPayment } from "./paymentThunk";

const initialState = {
  paymentRes: { status: 0, data: [] },
  verifyPaymentRes: { status: 0, data: [] },
};

export const paymentSlice = createSlice({
  name: "payment",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(makePayment.pending.toString(), (state) => {
      return {
        ...state,
        paymentRes: { status: APIstatus.IN_PROGRESS, data: [] },
      };
    });
    builder.addCase(makePayment.fulfilled.toString(), (state, action) => {
      return {
        ...state,
        paymentRes: {
          status: APIstatus.SUCCESS,
          data: action.payload,
        },
      };
    });
    builder.addCase(makePayment.rejected.toString(), (state) => {
      return {
        ...state,
        paymentRes: { status: APIstatus.FAILURE, data: [] },
      };
    });

    builder.addCase(verifyPayment.pending.toString(), (state) => {
      return {
        ...state,
        verifyPaymentRes: { status: APIstatus.IN_PROGRESS, data: [] },
      };
    });
    builder.addCase(verifyPayment.fulfilled.toString(), (state, action) => {
      return {
        ...state,
        verifyPaymentRes: {
          status: APIstatus.SUCCESS,
          data: action.payload,
        },
      };
    });
    builder.addCase(verifyPayment.rejected.toString(), (state) => {
      return {
        ...state,
        verifyPaymentRes: { status: APIstatus.FAILURE, data: [] },
      };
    });
  },
});
