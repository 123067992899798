import tokenService from "auth/tokenService";
import posthog from "posthog-js";

const getCurrentUserId = tokenService.getUserId();
const getCurrentUserName = tokenService.getUserName();

export const captureEvent = (event, data) => {
  if (process.env.NODE_ENV === "production") {
    posthog.capture(event, {
      ...(getCurrentUserId && { userId: getCurrentUserId }),
      ...(getCurrentUserName && { userName: getCurrentUserName }),
      ...(data && data),
    });
  }
};

export const setGlobalLanguageId = (lang) => {
  localStorage.setItem("globalLangId", lang);
};

export const getGlobalLangId = () => {
  return localStorage.getItem("globalLangId");
};

export const saveAllLangToLocalStorage = (languages) => {
  return localStorage.setItem("allLang", JSON.stringify(languages));
};

export const getAllLangFromLocalStorage = () => {
  return JSON.parse(localStorage.getItem("allLang"));
};
