import React from "react";
import { Modal, Spin } from "antd";
import Markdown from "react-markdown";
import MarkdownChartComponent from "components/layout-components/markdownChartComponent";

const DefinitionModal = ({ isVisible, onCancel, loading, definition }) => {
  // Custom renderer for code blocks to handle charts
  const components = {
    code({ node, inline, className, children, ...props }) {
      const match = /chart/.exec(className || "");
      try {
        if (match) {
          // Parse the JSON data from the code block
          const chartData = JSON.parse(children);

          // Render the Chart component with the parsed data
          return (
            <MarkdownChartComponent
              type={chartData.type}
              data={chartData.data}
              labels={chartData.labels}
              showGrid={chartData.showGrid}
            />
          );
        }

        return inline ? (
          <code {...props}>{children}</code>
        ) : (
          <pre {...props}>
            <code>{children}</code>
          </pre>
        );
      } catch (error) {
        console.log(error);
        return (
          <pre {...props}>
            <code>{children}</code>
          </pre>
        );
      }
    },
  };
  return (
    <Modal
      title={definition ? definition.term : ""}
      visible={isVisible}
      onCancel={onCancel}
      footer={null}
      zIndex={11}
    >
      <Spin spinning={loading}>
        <p>
          {definition ? (
            <Markdown
              components={components}
              children={definition.value}
              className="result-html full-height text-editor-preview"
            />
          ) : (
            `Loading definition...`
          )}
        </p>
      </Spin>
    </Modal>
  );
};

export default DefinitionModal;
