import React, { useState, useEffect } from "react";
import { Route, Switch, Redirect, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Modal, Spin, ConfigProvider } from "antd";
import { getDefinitionByKey } from "redux/toolkit/definition/definitionThunk";
import AppLayout from "layouts/app-layout";
import AuthLayout from "layouts/auth-layout";
import AppLocale from "lang";
import { IntlProvider } from "react-intl";
import { APP_PREFIX_PATH, AUTH_PREFIX_PATH } from "configs/AppConfig";
import useBodyClass from "hooks/useBodyClass";
import Markdown from "react-markdown";
import tokenService from "auth/tokenService";
import MarkdownChartComponent from "components/layout-components/markdownChartComponent";

const Views = ({
  locale,
  location,
  direction,
  getDefinitionByKey,
  getDefinitionByKeyRes,
}) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const currentAppLocale = AppLocale[locale];
  useBodyClass(`dir-${direction}`);

  const authToken = tokenService.getToken();

  useEffect(() => {
    const handleClick = (e) => {
      const target = e.target;
      const parent = target.parentElement;
      if (target.tagName === "A" || (parent && parent.tagName === "A")) {
        const url = target.getAttribute("href");
        if (url && url.includes("sumgya-definition")) {
          e.preventDefault();
          e.stopPropagation();
          const parts = url.split("sumgya-definition/");
          if (parts.length > 1) {
            const value = parts[1];
            setIsModalVisible(true);
            setLoading(true);
            getDefinitionByKey(value).finally(() => setLoading(false));
          }
        }
      }
    };

    document.addEventListener("click", handleClick);

    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, [getDefinitionByKey]);

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  // Custom renderer for code blocks to handle charts
  const components = {
    code({ node, inline, className, children, ...props }) {
      const match = /chart/.exec(className || "");
      try {
        if (match) {
          // Parse the JSON data from the code block
          const chartData = JSON.parse(children);

          // Render the Chart component with the parsed data
          return (
            <MarkdownChartComponent
              type={chartData.type}
              data={chartData.data}
              labels={chartData.labels}
              showGrid={chartData.showGrid}
            />
          );
        }

        return inline ? (
          <code {...props}>{children}</code>
        ) : (
          <pre {...props}>
            <code>{children}</code>
          </pre>
        );
      } catch (error) {
        console.log(error);
        return (
          <pre {...props}>
            <code>{children}</code>
          </pre>
        );
      }
    },
  };

  return (
    <IntlProvider
      locale={currentAppLocale.locale}
      messages={currentAppLocale.messages}
    >
      <ConfigProvider locale={currentAppLocale.antd} direction={direction}>
        <Switch>
          <Route exact path="/">
            <Redirect
              to={authToken ? APP_PREFIX_PATH : `${AUTH_PREFIX_PATH}/login`}
            />
          </Route>
          <Route path={`${AUTH_PREFIX_PATH}/login`}>
            {authToken ? (
              <Redirect to={APP_PREFIX_PATH} />
            ) : (
              <AuthLayout direction={direction} />
            )}
          </Route>
          <Route path={`${AUTH_PREFIX_PATH}/register`}>
            {authToken ? (
              <Redirect to={APP_PREFIX_PATH} />
            ) : (
              <AuthLayout direction={direction} />
            )}
          </Route>
          <Route path={APP_PREFIX_PATH}>
            {authToken ? (
              <AppLayout direction={direction} location={location} />
            ) : (
              <Redirect to={`${AUTH_PREFIX_PATH}/login`} />
            )}
          </Route>
          <Route>
            <Redirect
              to={authToken ? APP_PREFIX_PATH : `${AUTH_PREFIX_PATH}/login`}
            />
          </Route>
        </Switch>

        <Modal
          className="common-modal"
          title={
            getDefinitionByKeyRes.data ? getDefinitionByKeyRes.data.term : ""
          }
          visible={isModalVisible}
          onCancel={handleCancel}
          footer={null}
        >
          <Spin spinning={loading}>
            <p>
              {getDefinitionByKeyRes?.data.value ? (
                <Markdown
                  className="result-html full-height text-editor-preview"
                  components={components}
                  children={getDefinitionByKeyRes.data.value}
                />
              ) : (
                `No Defination available for this term!`
              )}
            </p>
          </Spin>
        </Modal>
      </ConfigProvider>
    </IntlProvider>
  );
};

const mapStateToProps = ({ theme, auth, definition }) => {
  const { locale, direction } = theme;
  const { token } = auth;
  const { getDefinitionByKeyRes } = definition;
  return { locale, token, direction, getDefinitionByKeyRes };
};

const mapDispatchToProps = {
  getDefinitionByKey,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Views));
