import { createAsyncThunk } from "@reduxjs/toolkit";
import secureApi from "auth/FetchInterceptor";
import { message } from "antd";

export const submitFeedback = createAsyncThunk(
  "feedback/submitFeedback",
  async (params, thunkAPI) => {
    const response = await secureApi
      .post(`/api/submit_feedback`, params)
      .catch((e) => {
        return e;
      });
    if (response.data.success) {
      message.success(response.data.message);
      return response.data.data;
    } else {
      message.error(response.data.message);
      return thunkAPI.rejectWithValue(response.data.data);
    }
  }
);
