import { createAsyncThunk } from "@reduxjs/toolkit";
import secureApi from "auth/FetchInterceptor";
import { message } from "antd";

export const makePayment = createAsyncThunk(
  "payment/makePayment",
  async (params, thunkAPI) => {
    const response = await secureApi
      .post(`/api/create_order`, params)
      .catch((e) => {
        return e;
      });
    if (response.data.success) {
      return response.data.data;
    } else {
      message.error(response.data.message);
      return thunkAPI.rejectWithValue(response.data.data);
    }
  }
);

export const verifyPayment = createAsyncThunk(
  "payment/verifyPayment",
  async (params, thunkAPI) => {
    const response = await secureApi
      .post(`/api/verify_payment`, params)
      .catch((e) => {
        return e;
      });
    if (response.data.success) {
      return response.data;
    } else {
      message.error(response.data.message);
      return thunkAPI.rejectWithValue(response.data.data);
    }
  }
);
